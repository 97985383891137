import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Stack,
  Pagination,
  useTheme,
  useMediaQuery,
  Tabs,
  Tab,
  Divider
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextField from "../form/FormTextField";
import { setAlert } from "../../features/slices/alertSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Constant from "../../features/constant";
import { DataGrid } from "@mui/x-data-grid";
import format from "date-fns/format";
import { lighten } from "@material-ui/core";
import Image from "../common/Image";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PaidIcon from '@mui/icons-material/Paid';
import { FocusTrap } from '@mui/base/FocusTrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

const Withdrawal = ({ withdrawDetails, useWithdrawMutation, user, useGetHistoryMutation, useGetBankAccountsQuery, useGetPromotionRecordMutation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const currentDate = new Date();
  const sevenDaysAgo = new Date();
  const [open, setOpen] = React.useState(false);
  const [bankAccountName, setBankAccountName] = React.useState('');
  const [bankCode, setBankCode] = React.useState('');
  const [bankAccountNumber, setBankAccountNumber] = React.useState('');
  const [bankAccountBsb, setBankAccountBsb] = React.useState('');
  const [withdrawalSubmit, setWithdrawalSubmit] = useState(false);

  const type = [
    { label: t("walletcard.transactionpage.all"), value: "All" },
    { label: t("walletcard.transactionpage.approved"), value: "Approved" },
    { label: t("walletcard.transactionpage.rejected"), value: "Rejected" },
    { label: t("walletcard.transactionpage.pending"), value: "Pending" },
  ];

  const [statusType, setStatusType] = useState(type[0].value);
  const [startDate, setStartDate] = useState(
    sevenDaysAgo.setDate(currentDate.getDate() - 7)
  );
  const [endDate, setEndDate] = useState(currentDate);
  const [offset, setOffset] = useState(0);

  const {
    data: bankDetails,
    isLoading: isBankDetailsLoading,
    isError: isBankDetailsError,
    isSuccess: isBankDetailsSuccess,
    error: bankDetailsError,
  } = useGetBankAccountsQuery();

  const [
    promotionRecord,
    result,
    // { data, isLoading, isError, isSuccess, error },
  ] = useGetPromotionRecordMutation();

  useEffect(() => {
    if (!(endDate < startDate)) {
      let data = {
        criteria: {
          dateFrom: format(startDate, "dd-MM-yyyy"),
          dateTo: format(endDate, "dd-MM-yyyy"),
          status: statusType.toUpperCase(),
        },
        offset: offset,
      };
      promotionRecord(data).unwrap();
    }
  }, [startDate, endDate, statusType, offset]);


  React.useEffect(() => {
    if (isBankDetailsSuccess && bankDetails?.result?.data[0]?.bankAccountName) {
      setBankAccountName(bankDetails.result.data[0].bankAccountName);
      setBankCode(bankDetails.result.data[0].bankCode);
      setBankAccountNumber(bankDetails.result.data[0].bankAccountNumber);
      setBankAccountBsb(bankDetails.result.data[0].bankAccountBsb);
    }
  }, [isBankDetailsSuccess, bankDetails]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (bankDetails) => {
    setOpen(false);
    setBankAccountName(bankDetails?.bankAccountName);
    setBankCode(bankDetails?.bankCode);
    setBankAccountNumber(bankDetails?.bankAccountNumber);
    setBankAccountBsb(bankDetails?.bankAccountBsb);
  };

  function SimpleDialog(props) {
    const { onClose, bankAccountName, open } = props;

    const handleClose = () => {
      onClose(bankAccountName);
    };

    const handleListItemClick = (bankDetails) => {
      onClose(bankDetails);
    };
    return (
      <Dialog onClose={handleClose} open={open} sx={{
        "& .MuiPaper-root": {
          bgcolor: theme.palette.background.paper,
          width: "100%",
          margin: 0,
          maxHeight: "100%",
        }
      }}>
        <DialogTitle sx={{ color: 'white' }} >{t("walletcard.withdrawalpage.selectbank")}</DialogTitle>
        <List sx={{ pt: 0 }}>
          {bankDetails?.result?.data?.map((data) => (
            <ListItem disableGutters key={data?.bankCode}>
              <ListItemButton onClick={() => {
                window.fbq('trackCustom', 'clickWithdrawal_' + data.bankCode);
                handleListItemClick(data);
              }}
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  padding: '10px'
                }}>
                <div sx={{ display: 'flex', flexDirection: 'column' }}>
                  <ListItemText sx={{ color: 'white' }} primary={data?.bankAccountNumber} />
                  <ListItemText sx={{ color: 'white' }} primary={data?.bankAccountName} />
                  <ListItemText sx={{ color: 'white' }} primary={data?.bankCode} />
                  <ListItemText sx={{ color: 'white' }} primary={data?.bankAccountBsb} />
                </div>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }


  const [openStates, setOpenStates] = useState(Array(result?.data?.result?.content.length).fill(false));

  const toggleOpenState = (index) => {
    setOpenStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const withdrawalSchema = yup.object().shape({
    bankCode: yup.string().required(t("validation.bankname")),
    bankAccountName: yup.string().required(t("validation.bankaccname")),
    bankAccountNo: yup.string().required(t("validation.bankaccno")),
    amount: yup
      .number()
      .typeError(t("validation.amountonly"))
      .min(700, "Min 700.00")
      .required(t("validation.amountisrequired")),
  });

  const { control, handleSubmit, reset, getValues } = useForm({
    resolver: yupResolver(withdrawalSchema),
  });

  const [withdraw, { data, isLoading, isError, isSuccess, error }] =
    useWithdrawMutation();

  const [showWithdrawalComponents, setShowWithdrawalComponents] = useState(false);


  const category = [
    { label: t("walletcard.transactionpage.deposit"), value: "Deposit" },
    { label: t("walletcard.transactionpage.withdraw"), value: "Withdraw" },
  ];

  const withdrawalLimit = [
    { label: 'Minimum Amount', value: process.env.REACT_APP_CURRENCY_CODE + ' ' + withdrawDetails?.withdrawalDetails?.minimum },
    { label: 'Maximum Amount', value: process.env.REACT_APP_CURRENCY_CODE + ' ' + withdrawDetails?.withdrawalDetails?.maximum },
    { label: 'Daily Maximum Amount', value: process.env.REACT_APP_CURRENCY_CODE + ' ' + withdrawDetails?.withdrawalDetails?.daily_amt },
    { label: 'Daily Limit', value: withdrawDetails?.withdrawalDetails?.daily_limit + ' Times' },

    // Add more data as needed
  ];

  const [tabValue, setTabValue] = useState(0);

  const withdrawalType = [];
  withdrawalType.push({ label: t("walletcard.depositpage.title") });
  const handleWithdrawButtonClick = () => {
    // Set the state to true to show the Grid container and TabPanel
    setShowWithdrawalComponents(true);
  };

  const handlePageChange = (e, p) => {
    setOffset(p - 1);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const WithdrawalContent = ({ t, mobileView, showWithdrawalComponents }) => {
    if (mobileView) {
      if (showWithdrawalComponents) {
        return null;
      }
    }
    return (
      <>
        <Typography fontWeight="bold" color="white" paddingTop="3px">
          {t("walletcard.withdrawalConditions")}
        </Typography>
        <Box
          border={1}
          borderRadius={1}
          padding={1}
          borderColor='#838383' // Customize border color as needed
          width={mobileView ? '70%' : '40%'} // Adjust padding for mobile
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" color="white" fontSize="14px">{t("walletcard.withdrawalpage.weeklyTurnOver")} :</Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight="bold" color="white" align="right" fontSize="14px">
                {process.env.REACT_APP_CURRENCY_CODE} {withdrawDetails?.playerTurnOver?.weeklyTurnOver || '0.00'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" color="white" fontSize="14px">{t("walletcard.withdrawalpage.monthlyTurnOver")} :</Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight="bold" color="white" align="right" fontSize="14px">
                {process.env.REACT_APP_CURRENCY_CODE} {withdrawDetails?.playerTurnOver?.monthlyTurnOver || '0.00'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  const submitWithdrawal = async (data) => {

    let newData = {
      amount: data.amount,
      bankAccountName: bankAccountName,
      bankAccountNumber: bankAccountNumber,
      bankCode: bankCode,
      bankAccountBsb: bankAccountBsb,
    };

    setWithdrawalSubmit(true);

    await withdraw(newData).unwrap().then(() => {
      setWithdrawalSubmit(false);
    }).catch((error) => {
      setWithdrawalSubmit(false);
    });
    navigate(Constant.getPath('/'));
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("walletcard.withdrawalpage.successmessage"),
          variant: "success",
        })
      );
      window.fbq('trackCustom', 'CompleteWithdrawal');
      reset();
    }
    if (!isLoading && isError) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error?.data?.message,
          variant: "error",
        })
      );
      reset();
    }
  }, [isLoading, isSuccess, isError]);

  return (
    <Container>

      <Typography fontWeight="bold" color="white">
        {" "}
        {t("WITHDRAWAL")}
      </Typography>
      <WithdrawalContent
        showWithdrawalComponents={showWithdrawalComponents}
        mobileView={mobileView}
        t={t}
      />
      {!mobileView && (
        <>
          <Box sx={{ height: 400, width: "100%", mt: "30px" }}>
            <DataGrid
              sx={{
                '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '&.MuiDataGrid-root': {
                  border: 'none',
                },
                "& .MuiDialogContent-root": {
                  overflowY: "auto",
                  "::-webkit-scrollbar": {
                    width: "12px",
                  },
                  "::-webkit-scrollbar-track": {
                    background: theme.palette.text.disabled,
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: theme.palette.background.paper,
                  },
                },
                color: "white",
                backgroundColor: theme.palette.primary.main,
              }}
              rows={
                result?.data?.result?.content.map((content, idx) => {
                  return {
                    ...content,
                    createdDate: format(
                      new Date(content?.createdDate),
                      "dd-MM-yyyy"
                    ),
                    id: idx + 1,
                  };
                }) || []
              }
              columns={[
                {
                  field: "createdDate",
                  headerName: t("walletcard.walletpage.period"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "title",
                  headerName: t("walletcard.withdrawalpage.title"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "validTurnOver",
                  headerName: t("walletcard.withdrawalpage.validbet"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "turnover",
                  headerName: t("walletcard.withdrawalpage.validbetcondition"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "status",
                  headerName: t("walletcard.walletpage.status"),
                  width: 150,
                  editable: false,
                }
              ]}
              hideFooter={true}
              rowsPerPageOptions={[5]}
              // checkboxSelection
              // disableSelectionOnClick
              // isCellEditable={false}
              onPageChange={(newPage) => setOffset(newPage)}
            />
          </Box>
          <Stack mt={2} spacing={2} size="small" alignItems="center">
            <Pagination
              // disabled={selectedAnnouncement.selected ? true : false}
              // onChange={handlePageChange}
              onChange={handlePageChange}
              count={
                result?.data?.result?.totalPages === 0
                  ? 1
                  : result?.data?.result?.totalPages
              }
              shape="rounded"
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "white",
                  "&.Mui-selected": {
                    background: lighten(theme.palette.background.paper, 0.02),
                    color: "white",
                  },
                },
              }}
            />
          </Stack>
        </>
      )}
      {!showWithdrawalComponents && (
        <>
          {mobileView && result?.data?.result?.content && result?.data?.result?.content.map((content, idx) => (
            <Box key={idx} border="1px solid white" marginTop="10px">
              <Button
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                onClick={() => toggleOpenState(idx)}
              >
                <div>
                  <Typography sx={{ fontSize: '10px', color: 'white', textAlign: 'left' }}>
                    {new Date(content.createdDate).toLocaleDateString(undefined, {
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    })}
                  </Typography>
                  <Typography sx={{ fontSize: '11px', color: 'white', fontWeight: 'bold' }}>
                    {content.title}
                  </Typography>
                </div>
                <div style={{ width: '65px', height: '30px', backgroundColor: '#838383', textAlign: 'center', lineHeight: '40px', margin: '5px', borderRadius: '5px' }}>
                  <Typography sx={{ color: 'white', textAlign: 'center', fontSize: '12px' }}>
                    {content.status}
                  </Typography>
                </div>
              </Button>
              <FocusTrap key={idx} open={openStates[idx]} disableRestoreFocus disableAutoFocus>
                <Stack alignItems="center" spacing={2}>
                  {openStates[idx] ? (
                    <Divider sx={{ backgroundColor: 'white', width: '100%' }} />
                  ) : (
                    <Button disabled={isLoading} onClick={() => toggleOpenState(idx)}>
                      <Typography sx={{ fontSize: '11px', color: 'white', fontWeight: 'bold' }}>
                        More
                      </Typography>
                    </Button>
                  )}
                  {openStates[idx] && (
                    <Grid container >
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: '10px', color: 'white', paddingLeft: '8px' }}>{t("walletcard.withdrawalpage.deposit")}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: '10px', color: 'white', paddingRight: '8px' }} align="right">{process.env.REACT_APP_CURRENCY_CODE} {content.deposit}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: '10px', color: 'white', paddingLeft: '8px' }}>{t("walletcard.withdrawalpage.title")}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: '10px', color: 'white', paddingRight: '8px' }} align="right">{content.title}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: '10px', color: 'white', paddingLeft: '8px' }}>{t("walletcard.withdrawalpage.validbet")}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: '10px', color: 'white', paddingRight: '8px' }} align="right">{process.env.REACT_APP_CURRENCY_CODE} {content.validTurnOver}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: '10px', color: 'white', paddingLeft: '8px', paddingBottom: '6px' }}>{t("walletcard.withdrawalpage.validbetcondition")}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: '10px', color: 'white', paddingRight: '8px', paddingBottom: '6px' }} align="right">{process.env.REACT_APP_CURRENCY_CODE} {content.turnover}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Stack>
              </FocusTrap>
            </Box>
          ))
          }
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box alignItems="center" mb="30px" mt="30px">
              <Button variant="contained" disabled={isLoading} onClick={handleWithdrawButtonClick}>
                {t("walletcard.withdraw")}
              </Button>
            </Box>
          </div>
        </>
      )}
      {showWithdrawalComponents && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box
                border={1}
                borderRadius={1}
                padding={1}
                borderColor="primary.main"
                width="100%"
                backgroundColor={theme.palette.primary.main}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <PaidIcon color="white"></PaidIcon>
                  </Grid>
                  <Grid item>
                    <Typography color="white">{t("walletcard.withdrawalpage.withdrawalbalance")} :</Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontWeight="bold" color="white">{process.env.REACT_APP_CURRENCY_CODE} {withdrawDetails?.totalValidTurnOver || '0.00'}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box
                border={1}
                borderRadius={1}
                padding={1}
                borderColor="secondary.main" // Customize border color as needed
                width="100%"
                backgroundColor={theme.palette.secondary.main}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <CardGiftcardIcon color="white"></CardGiftcardIcon>
                  </Grid>
                  <Grid item>
                    <Typography color="white">{t("walletcard.withdrawalpage.promotionbalance")}:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontWeight="bold" color="white">{process.env.REACT_APP_CURRENCY_CODE} {withdrawDetails?.totalPromotionBalance || '0.00'}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <br />
          <Typography fontWeight="bold" color="white">
            {t("walletcard.withdrawaloption")}
          </Typography>
          <Box px={1}>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              onChange={handleTabChange}
              sx={{
                marginLeft: "1px"
              }}
            >
              {withdrawalType.map((type, index) => (
                <Tab
                  icon={
                    <Image
                      width="40px"
                      height="40px"
                      src={process.env.REACT_APP_PROFILE + `/payment_method_` + index + `.png`}
                    />}
                  key={index}
                  label={type.label}
                  sx={{
                    color: theme.palette.primary.main,
                    border:
                      index === tabValue && `2px solid ${theme.palette.primary.main}` ||
                      index !== tabValue && `1px solid ${theme.palette.button.faded.main}`,
                    fontWeight: 600,
                    borderRadius: "5px",
                    width: "180px",
                    padding: "10px 5px",
                    margin: "0  10px",
                    background: "rgba(255,255,255,0.25)"
                  }}
                  disableRipple
                />
              ))}
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Box display="flex" flexDirection="column">
                <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '2px' }}>
                  <Button
                    variant="contained"
                    onClick={handleClickOpen}
                    sx={{
                      width: mobileView ? '30%' : '20%',
                      fontSize: '15px',
                      padding: 0
                    }}
                  >
                    {t("walletcard.withdrawalpage.selectbank")}
                  </Button>
                </div>
                <SimpleDialog
                  bankaccountname={bankAccountName}
                  open={open}
                  onClose={handleClose}
                />
                <form onSubmit={handleSubmit(submitWithdrawal)}>
                  <FormTextField
                    label={t("walletcard.withdrawalpage.bankname")}
                    control={control}
                    controllerName="bankCode"
                    items={withdrawDetails?.banks
                      ?.filter((item) => item.status === "ACTIVE")
                      .map((item) => item.content)}
                    size="small"
                    disabled={true}
                    dynamicValue={bankCode}
                    defaultValue={bankCode}
                  />
                  <FormTextField
                    label={t("walletcard.withdrawalpage.bankaccountname")}
                    control={control}
                    controllerName="bankAccountName"
                    disabled={true}
                    size="small"
                    dynamicValue={bankAccountName}
                    defaultValue={bankAccountName}

                  />
                  <FormTextField
                    label={t("walletcard.withdrawalpage.bankaccountnumber")}
                    control={control}
                    controllerName="bankAccountNo"
                    defaultValue={bankAccountNumber}
                    size="small"
                    disabled={true}
                    dynamicValue={bankAccountNumber}
                  />
                  <FormTextField
                    label={t("walletcard.withdrawalpage.bankaccountbsb")}
                    control={control}
                    controllerName="bankAccountBsb"
                    defaultValue={bankAccountBsb}
                    size="small"
                    disabled={true}
                    dynamicValue={bankAccountBsb}
                  />
                  <FormTextField
                    label={t("walletcard.withdrawalpage.amount")}
                    control={control}
                    controllerName="amount"
                    defaultValue={""}
                    placeholder="Min 700.00"
                    size="small"
                  />
                  <Box alignSelf="center" mb="30px" mt="30px">
                    <Button variant="contained" type="submit" disabled={withdrawalSubmit} >
                      {t("walletcard.submit")}
                    </Button>
                  </Box>
                </form>
                <Container sx={{ border: '1px solid #ccc', borderRadius: 4, padding: 2 }}>
                  <Typography color={theme.palette.secondary.main}>
                    {t("walletcard.withdrawalpage.withdrawalLimit")}
                  </Typography>
                  {withdrawalLimit.map((item, index) => (
                    <Grid container key={index} spacing={2} >
                      <Grid item xs={6}>
                        <Typography color="white">{item.label}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color="white" align="right">{item.value}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Container>
              </Box>
            </TabPanel>
          </Box>
        </>
      )}
    </Container>
  );
};

export default Withdrawal;
